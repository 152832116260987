import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Row, Container } from 'react-bootstrap';
import { FormTypes, sectionList } from '../../../constants/queryFragments';
import loadable from '@loadable/component';
import * as Styled from './contentListColumnGreyBackgroundStyles'
import {
  Body,
  Columns,
  ColumnThird,
  HeaderH2,
  HeaderH3,
  MainContainerGrey,
  ParaText,
  HeadingH4,
  UnorderedList,
  LinkStyle,
  ListItem,
  HorizontalBreak,
  SpanStyle,
} from '../../../styles';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../../utils';

const ContentListColumnGreyBackground = ({ sectionData, location }) => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    setLoaded(true);
  }, []);
  React.useEffect(async () => {
    if (sectionData?.elementId === location?.hash?.replace('#', '')) {
      const waitedID = await new Promise((resolve) => {
        document?.getElementById(sectionData?.elementId) && resolve(true);
      });

      if (waitedID) {
        setTimeout(() => {
          window.location.href = `#${sectionData?.elementId}`;
        }, 500);
      }
    }
  }, [sectionData?.elementId]);

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <HeaderH2 style={{ textAlign: 'center' }}>{children}</HeaderH2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <HeaderH3 style={{ textAlign: 'center' }}>{children}</HeaderH3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => <HeadingH4>{children}</HeadingH4>,
      [BLOCKS.PARAGRAPH]: (node, children) => <Styled.ParaText>{children}</Styled.ParaText>,
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
      [INLINES.HYPERLINK]: (node, children) => (
        <LinkStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                 process.env.GATSBY_DOMAIN_URL + node?.data?.uri
              )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </LinkStyle>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return !loaded ? (
    <MainContainerGrey>
      <Styled.Body>
        {sectionData?.header?.trim() && (
          <HeaderH2 style={{ textAlign: 'left' }}>
            {sectionData?.header}
          </HeaderH2>
        )}
        {sectionData?.subtext?.trim() && (
          <HeaderH3 style={{ textAlign: 'left' }}>
            {sectionData?.subtext}
          </HeaderH3>
        )}
        {sectionData?.listDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.listDetails?.raw),
            optionsMainStyle
          )}
        {sectionData?.contentDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.contentDetails?.raw),
            optionsMainStyle
          )}
        {sectionData && (
          <>
            <Columns>
              <Row className="g-0">
                {sectionData?.list &&
                  sectionData?.list?.map((section, index) => {
                    if (
                      section?.type &&
                      sectionList?.includes(section?.type) &&
                      !FormTypes?.includes(section?.type)
                    ) {
                      const SectionComponent = loadable(() =>
                        import(
                          `../../../components/dynamic/${section?.type}/${section?.type}`
                        )
                      );
                      return (
                        <Columns lg={12} key={index}>
                          <SectionComponent
                            key={index}
                            sectionData={section}
                            location={location}
                          />
                        </Columns>
                      );
                    }
                  })}
              </Row>
            </Columns>
          </>
        )}
      </Styled.Body>
    </MainContainerGrey>
  ) : (
    <MainContainerGrey>
      <Styled.Body>
        {sectionData?.elementId?.trim() && (
          <SpanStyle id={sectionData?.elementId?.trim()} />
        )}
        {sectionData?.header?.trim() && (
          <HeaderH2 style={{ textAlign: 'center' }}>
            {sectionData?.header}
          </HeaderH2>
        )}
        {sectionData?.subtext?.trim() && (
          <HeaderH3 style={{ textAlign: 'center' }}>
            {sectionData?.subtext}
          </HeaderH3>
        )}
        {sectionData?.listDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.listDetails?.raw),
            optionsMainStyle
          )}
        {sectionData?.contentDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.contentDetails?.raw),
            optionsMainStyle
          )}
        {sectionData && (
          <>
            <Columns>
              <Row className="g-0">
                {sectionData?.list &&
                  sectionData?.list?.map((section, index) => {
                    if (section?.type && sectionList?.includes(section?.type)) {
                      const SectionComponent = loadable(() =>
                        import(
                          `../../../components/dynamic/${section?.type}/${section?.type}`
                        )
                      );
                      return (
                        
                          <Columns lg={12} key={index}>
                            <SectionComponent
                            key={index}
                              sectionData={section}
                              location={location}
                            />
                          </Columns>
                       
                      );
                    }
                  })}
              </Row>
            </Columns>
          </>
        )}
      </Styled.Body>
    </MainContainerGrey>
  );
};
export default ContentListColumnGreyBackground;
